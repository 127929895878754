import React from "react"
import theme from '@styles/theme'
import { Footer } from "@components/Footer"

import {
  Responsive,
} from "semantic-ui-react"

import { mapToTopNavMenu, AnnouncementItems, mapToAnnouncementItems, NavItem } from "./mapper"

import { PageHeader } from "../PageHeader"

import { getFriendlyDateTime } from '@utils/formatDate'
import { LayoutContainer, LayoutContainerDark } from "./DesktopLayoutContainer.styles"
import { MessageBanner } from "@components/MessageBanner/MessageBanner"
import { hash } from "@utils/Helpers"
import { showMessage, showMessageBanner, showPopupOnPage } from "./Layout"
import { PromotionPopupKenticoModel } from "@models/PromotionPopup/PromotionPopup"
import { mapToPromotionPopupForms, mapToPromotionPopups } from "@mappers/PromotionPopup/mapper"
import { PromotionPopupFormKenticoModel } from "@models/PromotionPopupForm/PromotionPopupForm"
import { PromotionFormModal } from "@components/PromotionModal/PromotionFormModal"
import { getCurrentBasket } from "@redux/localStorage/basket"
import { checkDesignForPromoService } from "@services/BasketService"
import PromotionModal from "@components/PromotionModal/PromotionModal"
import { getCurrentCheckout } from "@redux/localStorage/checkout"


interface DesktopContainerProps {
  apiUrl: string
  emailValidationToken: string
  navItems: NavItem[],
  announcementItems: AnnouncementItems[],
  promotionPopupItems: PromotionPopupKenticoModel[],
  promotionPopupForms: PromotionPopupFormKenticoModel[],
  currentStep?: number,
  children: any,
  version: string,
  windowWidth: number,
  siteBuildInfo: {
    environment: string
    lastBuilt: string
  },
  beforeCloseFired: boolean
}
interface DesktopContainerStates {
  updatingMessageBanner: boolean,
  allowDesign: boolean
}

class DesktopLayoutContainer extends React.Component<DesktopContainerProps, DesktopContainerStates> {
  constructor(props: DesktopContainerProps) {
    super(props)
    this.state = {
      updatingMessageBanner: false,
      allowDesign: false
    }
  }
  removeMessageBanner = (key: string) => {
    this.setState({ updatingMessageBanner: true });
    // if (typeof window !== 'undefined') {
    //   localStorage.setItem(key, "true");
    // }
   // this.setState({ updatingMessageBanner: false });
  }
  
  componentDidMount(){
    const basket = getCurrentBasket()
    const checkout = getCurrentCheckout()
    if(basket) {
      if(basket.basketLines && basket.basketLines.length > 0){   
        const popups = mapToPromotionPopups(this.props.promotionPopupItems); 
        var promoCode = ''
        popups.forEach((popup) => {          
          if (showMessage(popup.startDate, popup.endDate) && showPopupOnPage(popup.allowedURLs)){        
              promoCode = popup.promoCode              
          }
        })      
        if(promoCode && checkout && !checkout.isDealerOrder){
          checkDesignForPromoService(this.props.apiUrl, promoCode, basket.basketId).then((data) =>{            
            if(data && data.Success && data.Data){              
                this.setState({allowDesign: true})               
            } 
          });
        }        
      }
    }
  }

  renderPromotionPopups = () => {
    const popups = mapToPromotionPopups(this.props.promotionPopupItems);
    const result: any[] = [];
    popups.forEach((popup, key) => {
      const id = popup.id.replace(' ', '');
        if (showMessage(popup.startDate, popup.endDate) && !this.state.updatingMessageBanner && showPopupOnPage(popup.allowedURLs) && this.state.allowDesign){        
          result.push(<PromotionModal key={key} hide={this.removeMessageBanner} data={popup} id={id} promoCode={popup.promoCode} apiUrl={this.props.apiUrl}/>);   
      }
    })
    return result;
  }
  renderPromotionPopupForms = () => {
    const popups = mapToPromotionPopupForms(this.props.promotionPopupForms);
    const forms: any[] = [];
    popups.forEach((popup, key) => {
      const id = "desktop-" + hash(popup.name);
      var showbanner = showMessageBanner(id);
      if (showMessage(popup.startDate, popup.endDate) && showbanner && showPopupOnPage(popup.allowedURLs))
        forms.push(<PromotionFormModal
          emailValidationToken={this.props.emailValidationToken} key={id} isMobile={false} apiUrl={this.props.apiUrl} show={this.props.beforeCloseFired} hide={this.removeMessageBanner} data={popup} id={id} />);
    })
    return forms;
  }
  renderAnnouncements = () => {
    const announcements = mapToAnnouncementItems(this.props.announcementItems);
    const result: any[] = [];
    announcements.forEach((ats, key) => {
      const id = hash(ats.title);
      if (showMessage(undefined, ats.expireDate) && showMessageBanner(id))
        result.push(<MessageBanner key={key} hide={this.removeMessageBanner} backgroundColor={ats.backgroundColor} messageHtml={ats.title} textColor={ats.textColor} id={id} />);
    })
    return result;
  }
  render() {
    const {
      apiUrl,
      emailValidationToken,
      navItems,
      children,
      version,
      currentStep,
      siteBuildInfo,
      windowWidth
    } = this.props;
    const topNavMenu = mapToTopNavMenu(navItems)
    const currentBuildId = hash(siteBuildInfo.lastBuilt);
    return (
      <Responsive as={version == 'simple' ? LayoutContainer : LayoutContainerDark} getWidth={() => { return windowWidth; }} minWidth={theme.responsive.desktop.minWidth}>
        <div style={{
          position: 'relative'
        }}>
          {/* {
            siteBuildInfo.environment !== 'production' && showMessageBanner(currentBuildId) &&
            <MessageBanner backgroundColor="black" hide={this.removeMessageBanner} textColor="white" id={currentBuildId} messageHtml={`Environment: ${siteBuildInfo.environment}, Last Built: ${getFriendlyDateTime(Date.parse(siteBuildInfo.lastBuilt))}`} />
          } */}
          {
            this.renderAnnouncements()
          }
          {
            this.renderPromotionPopups()
          }
          {
            this.renderPromotionPopupForms()
          }
          <PageHeader apiUrl={apiUrl} currentStep={currentStep} topNavMenu={topNavMenu} version={version} />
          {children}
        </div>
        {
          version !== 'blank' &&
          <div>
            <Footer version={version} backgroundColor={theme.brand.colors.black} />
          </div>
        }
      </Responsive>
    )
  }
}

export default DesktopLayoutContainer
