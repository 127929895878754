import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Footer } from "@components/Footer"

import theme from '@styles/theme'
import {
  Responsive,
  Menu,
  Accordion,
  List,
  Sidebar
} from "semantic-ui-react"
import {
  SidebarTitle,
  SidebarNavItem,
  SidebarNavItemMenu,
  NavSubMenu,
  HamburgerIcon,
  SubMenuIcon,
  NavAccordionTitle,
  NavAccordionContent,
  ExternalNavLink,
  SidebarHeader,
  NavSubMenuItem,
  LogoIcon,
  LogoLink,
  SidebarPushableContainer,
  MobileTopMessageWrapper,
  SidebarContainer,
  MobileShoppingCartContainer,
  SidebarNavItemsContainer
} from "./MobileLayoutContainer.styles"

import { mapToTopNavMenu, AnnouncementItems, mapToAnnouncementItems, NavItem } from "./mapper"

import { MobilePageHeader } from "@components/PageHeader"
import { Hyperlink, ShoppingCartContainer } from "@elements/index"
import { LayoutContainer, LayoutContainerDark } from "./DesktopLayoutContainer.styles"
import { getFriendlyDateTime } from "@utils/formatDate"
import { MessageBanner } from "@components/MessageBanner/MessageBanner"
import { hash } from "@utils/Helpers"
import { showMessage, showMessageBanner, showPopupOnPage } from "./Layout"
import { PromotionPopupKenticoModel } from "@models/PromotionPopup/PromotionPopup"
import { mapToPromotionPopupForms, mapToPromotionPopups } from "@mappers/PromotionPopup/mapper"
import { PromotionPopupFormKenticoModel } from "@models/PromotionPopupForm/PromotionPopupForm"
import { PromotionFormModal } from "@components/PromotionModal/PromotionFormModal"
import UserLink from "@elements/UserLink/UserLink"
import PromotionModal from "@components/PromotionModal/PromotionModal"
import { getCurrentBasket } from "@redux/localStorage/basket"
import { checkDesignForPromoService } from "@services/BasketService"
import { getCurrentCheckout } from "@redux/localStorage/checkout"

interface MobileLayoutProps {
  apiUrl: string
  emailValidationToken: string
  navItems: NavItem[],
  version: string,
  announcementItems: AnnouncementItems[]
  promotionPopupItems: PromotionPopupKenticoModel[]
  promotionPopupForms: PromotionPopupFormKenticoModel[]
  currentStep?: number,
  children: any,
  windowWidth: number,
  siteBuildInfo: {
    environment: string
    lastBuilt: string
  },
  beforeCloseFired: boolean
}
interface MobileLayoutStates {
  activeIndex: number
  sidebarOpened: boolean
  updatingMessageBanner: boolean
  allowDesign: boolean
}

class MobileLayoutContainer extends React.Component<MobileLayoutProps, MobileLayoutStates> {

  constructor(props: MobileLayoutProps) {
    super(props)
    this.state = {
      activeIndex: -1,
      sidebarOpened: false,
      updatingMessageBanner: false,
      allowDesign: false
    }
  }

  componentDidMount(){
    const basket = getCurrentBasket()
    const checkout = getCurrentCheckout()
    if(basket) {
      if(basket.basketLines && basket.basketLines.length > 0){   
        const popups = mapToPromotionPopups(this.props.promotionPopupItems); 
        var promoCode = ''
        popups.forEach((popup) => {          
          if (showMessage(popup.startDate, popup.endDate) && showPopupOnPage(popup.allowedURLs)){        
              promoCode = popup.promoCode              
          }
        })      
        if(promoCode && checkout && !checkout.isDealerOrder){
          checkDesignForPromoService(this.props.apiUrl, promoCode, basket.basketId).then((data) =>{            
            if(data && data.Success && data.Data){              
                this.setState({allowDesign: true})               
            } 
          });
        }        
      }
    }
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleSidebarShow = () => this.setState({ sidebarOpened: true })
  handleToggleSidebar = () => {
    this.setState((prevState, prevProps) => {
      return { sidebarOpened: prevState.sidebarOpened ? false : true }
    })
  }
  handleSidebarMenu = (index: number) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }
  removeMessageBanner = (key: string) => {
    this.setState({ updatingMessageBanner: true });
    // if (typeof window !== 'undefined') {
    //   localStorage.setItem(key, "true");
    // }
    // this.setState({ updatingMessageBanner: false });
  }
  renderPromotionPopups = () => {
    const popups = mapToPromotionPopups(this.props.promotionPopupItems);
    const result: any[] = [];
    popups.forEach((popup, key) => {
      const id = popup.id.replace(' ', '');
      if (showMessage(popup.startDate, popup.endDate) && !this.state.updatingMessageBanner && showPopupOnPage(popup.allowedURLs) && this.state.allowDesign)
        result.push(<PromotionModal key={key} hide={this.removeMessageBanner} data={popup} id={id} promoCode={popup.promoCode} apiUrl={this.props.apiUrl}/>);   
    })
    return result;
  }
  renderPromotionPopupForms = () => {
    const popups = mapToPromotionPopupForms(this.props.promotionPopupForms);
    const forms: any[] = [];
    popups.forEach((popup, key) => {
      const id = "mobile-" + hash(popup.name);
      var showbanner = showMessageBanner(id);
      if (showMessage(popup.startDate, popup.endDate) && showbanner)
        forms.push(<PromotionFormModal
          emailValidationToken={this.props.emailValidationToken} isMobile={true} apiUrl={this.props.apiUrl} show={this.props.beforeCloseFired} key={id} hide={this.removeMessageBanner} data={popup} id={id} />);
    })
    return forms;
  }
  renderAnnouncements = () => {
    const announcements = mapToAnnouncementItems(this.props.announcementItems);
    const result: any[] = [];
    announcements.forEach((ats, key) => {
      const id = hash(ats.title);
      if (showMessage(undefined, ats.expireDate) && showMessageBanner(id))
        result.push(<MessageBanner key={key} hide={this.removeMessageBanner} backgroundColor={ats.backgroundColor} messageHtml={ats.titleMobile} textColor={ats.textColor} id={id} />);
    })
    return result;
  }
  render() {
    const { sidebarOpened, activeIndex } = this.state;
    const {
      apiUrl,
      navItems,
      version,
      currentStep,
      children,
      siteBuildInfo,
      windowWidth
    } = this.props;

    const topNavMenuItems = mapToTopNavMenu(navItems)

    return (
      <Responsive
        as={version == 'simple' ? LayoutContainer : LayoutContainerDark}
        getWidth={() => { return windowWidth; }}
        maxWidth={theme.responsive.tablet.maxWidth}
      >

        <SidebarPushableContainer>
          <SidebarContainer
            as={Menu}
            animation="overlay"
            onHide={this.handleSidebarHide}
            onShow={this.handleSidebarShow}
            vertical
            width="wide"
            visible={sidebarOpened}
          >

            <SidebarTitle>
              <SidebarHeader>
                <HamburgerIcon link name="close" size="huge" onClick={this.handleSidebarHide} />
                <LogoLink to="/">
                  <LogoIcon width={115} height={34} reverse={true} />
                </LogoLink>
                <MobileShoppingCartContainer>
                  <UserLink apiUrl={apiUrl} />
                  <ShoppingCartContainer fill={theme.brand.colors.white} isSimple={true} apiUrl={apiUrl} />
                </MobileShoppingCartContainer>
              </SidebarHeader>
            </SidebarTitle>
            <SidebarNavItemsContainer>
              {
                topNavMenuItems.map((navmenu, idx) => {
                  const {
                    title,
                    url,
                    sub_nav
                  } = navmenu;
                  const isExternalUrl = url.startsWith("http");
                  return sub_nav.length === 0 ? (
                    isExternalUrl ?
                      <SidebarNavItem as={ExternalNavLink} href={url} onMouseEnter={() => this.handleSidebarMenu(idx)} index={idx} key={idx} >
                        {title}
                      </SidebarNavItem>
                      :
                      <SidebarNavItem as={Link} to={url.endsWith('/') ? url : url + "/"} onMouseEnter={() => this.handleSidebarMenu(idx)} index={idx} key={idx} >
                        {title}
                      </SidebarNavItem>
                  ) : (<Fragment key={idx}>
                    <SidebarNavItemMenu>
                      <Accordion>
                        <NavAccordionTitle active={activeIndex === idx} index={idx} onMouseEnter={() => this.handleSidebarMenu(idx)} >
                          <SubMenuIcon name={activeIndex === idx ? 'minus' : 'plus'} />
                          {title}
                        </NavAccordionTitle>
                        <NavAccordionContent active={activeIndex === idx} >
                          <NavSubMenu borderless={true} as={List} vertical>
                            {
                              sub_nav.map((submenu, j) => (
                                <NavSubMenuItem as={List.Item} key={idx + "" + j}>
                                  <Hyperlink url={submenu.url}>
                                    {submenu.title}
                                  </Hyperlink>
                                </NavSubMenuItem>
                              ))
                            }
                          </NavSubMenu>
                        </NavAccordionContent>
                      </Accordion>
                    </SidebarNavItemMenu>
                  </Fragment>
                    )
                })
              }
            </SidebarNavItemsContainer>
          </SidebarContainer>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <MobileTopMessageWrapper>
              {
                siteBuildInfo.environment !== 'production' && showMessageBanner(hash(siteBuildInfo.lastBuilt)) &&
                <MessageBanner backgroundColor="black" textColor="white" id={hash(siteBuildInfo.lastBuilt)} hide={this.removeMessageBanner} messageHtml={`${siteBuildInfo.environment}, Last Built: ${getFriendlyDateTime(Date.parse(siteBuildInfo.lastBuilt))}`} />
              } 
              {
                this.renderAnnouncements()
              }
              {
                this.renderPromotionPopups()
              }
              {
                this.renderPromotionPopupForms()
              }
            </MobileTopMessageWrapper>
            <MobilePageHeader apiUrl={apiUrl} version={version} currentStep={currentStep} handleToggleSidebar={this.handleToggleSidebar} sidebarOpened={sidebarOpened} />
            <style dangerouslySetInnerHTML={{
              __html: `
                #___gatsby{
                    height: auto !important;
                }
                `}} />
            {children}
            {
              version !== "simple" &&
              <Footer version={version} backgroundColor={theme.brand.colors.black} />
            }
          </Sidebar.Pusher>
        </SidebarPushableContainer>
      </Responsive>
    )
  }
}

export default MobileLayoutContainer
